import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Web3 from 'web3';
const jwt = require('jsonwebtoken');
const secretKey = 'your_secret_key'; // Replace with your own secret key

Vue.use(Vuex)
const CONTRCTAS = require("../contracts/contracts.json")
axios.defaults.headers.common['Authorization'] = 'eyJhbGciOiJIUzI1NiJ9.ZnVzZS5nb2xkLUFwcA.IWwdTWsFFJJj87KBFwodDfbKF2Hk9hqhn2C7JtFAwzM';
function isImageFile(url) {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
  const extension = getFileExtension(url);
  return imageExtensions.includes(extension);
}
function getFileExtension(url) {
  const parts = url.split('.');
  if (parts.length === 0) {
    return null; // No file extension found
  }
  return parts[parts.length - 1].toLowerCase();
}
function isNumeric(input) {
  // Use a regular expression to check if the input contains only numbers
  return /^[0-9]+$/.test(input);
}
export default new Vuex.Store({
  state: {
    TOKEN:null,
    myRewards:[],
    allRewardsGrouped:{},
    web3:null,
PFUSEG_BALANCE:0.000,
PGOLDX_BALANCE:0.000,
PTOTAL_GOLDX_REWARDED_FROM_PLATFOM_BUYS:0, //done
PTOTAL_GOLDX_REWARDED_FROM_PLATFORM_SELLS:0,
PTOTAL_GOLDX_REWARDED_FROM_PLATFORM_EXCHANGE:0,
GOLDX_WALLET:0,
FUSEG_WALLET:0,
NFTLOADING:false,
PTOTAL_GOLDX_REWARDED_FROM_DEX_BUYS:0,
PTOTAL_GOLDX_REWARDED_FROM_DEX_SELLS:0,
PTOTAL_GOLDX_EXCHANGED_TO_FUSEG:0,
PTOTAL_GOLDS_EXCHANGED_TO_NFTs:0,
refRewards:0,
PMRewards:0,
GRefiner:0,
OGRefiner:0,
ARefiner:0,
GXRefiner:0,
GTOTAL_REFERRAL_GOLDX:0,
GTOTAL_GOLDX_REWARDED:0,
GTOTAL_GOLDX_REWARDED_TODAY:0,
GTOTAL_GOLDX_REWARDED_BY_REFERALS:0,
GTOTAL_GOLDX_CIRCULATING_SUPPLY:0,
GTOTAL_GOLDX_BURNED:0,
GTOTAL_GOLDX_EXCHANGED_TO_FUSEG:0,
GTOTAL_GOLDS_EXCHANGED_TO_NFTs:0,
GTOTAL_GOLDX_REMAINING_TO_BE_REWARDED:0,

STOTAL_GOLDX_HOLDERS:0,
STOTAL_REFERRAL_GOLDX:0,
STOTAL_GOLDX_REWARDED_FROM_PLATFORM_BUYS:0,
STOTAL_GOLDX_REWARDED_FROM_PLATFORM_SELLS:0,
STOTAL_GOLDX_REWARDED_FROM_PLATFORM_EXCHANGE:0,
STOTAL_GOLDX_REWARDED_FROM_DEX_BUYS:0,
STOTAL_GOLDX_REWARDED_FROM_DEX_SELLS:0,
STOTAL_GOLDX_EXCHANGED_TO_FUSEG:0,
STOTAL_GOLDS_EXCHANGED_TO_NFTs:0,
dark:false,
FUSEG_SYMBOL:null,
FUSEG_DECIMAL:null,
GOLDX_USERS:{},
FUSEG_PRICE:0,
GOLDX_SYMBOL:null,
GOLDX_DECIMALS:18,
totalSupply:0,
provider:null,
totalExchanged:[],
totalSells:[],
approvedNFTs:[],
appPros:[],
miners:null,
    refiners: null,
    prospectors: null,
appMin:[],
appRef:[],
pendPros:[],
pendMin:[],
prvPurchase: null,
canConnectWallet:false,
pendRef:[],
totalBuys:[],
NFTs:[],
soldMiners:0,
soldPros:0,
totalMiners:0,
refTotal:0,
refSold:0,
totalPros:0,
NFTSTATS:[],
pending:[],
verified:[],
pendingNFTs:[],
activities:[],
approved:{},
dist:{},
nftUsers:{},
nftUsersEmails:{},
userWallet:null,
API:"https://devapp.fuse.gold",
// API:"http://localhost:3030",
// NFTAPI:"http://localhost:3030",
networkID:'0xa573',
nftbyround:{},
//production 0x8ca7B57df55BfD8bd1eC0754Fb383f93849025c0
GOLDXadd: CONTRCTAS.bsc_testnet.GOLDX.address,
GOLDXabi: CONTRCTAS.bsc_testnet.GOLDX.abi,
FUSEGadd: CONTRCTAS.bsc_testnet.FUSEG.address,
FUSEGabi: CONTRCTAS.bsc_testnet.FUSEG.abi,
RewardAdd: CONTRCTAS.bsc_testnet.RewardVault.address,
Rewardabi: CONTRCTAS.bsc_testnet.RewardVault.abi,
pluginAddress:CONTRCTAS.goldx.plugin.address,
pluginAbi:CONTRCTAS.goldx.plugin.abi,
marketAddress:CONTRCTAS.goldx.market.address,
marketAbi:CONTRCTAS.goldx.market.abi,
refCAddress:CONTRCTAS.goldx.ref.address,
refCAddressEther:CONTRCTAS.eth.ref.address,
refCAddressBNB:CONTRCTAS.bsc.ref.address,
refCAbi:CONTRCTAS.goldx.ref.abi,
refCAbiEther:CONTRCTAS.eth.ref.abi,
refCAbiBNB:CONTRCTAS.bsc.ref.abi,
HBSC_ABI:CONTRCTAS.goldx.hbsc.abi,
WGOLDX_ABI:CONTRCTAS.goldx.wgoldx.abi,
totalSales:{total:0, sale: 0, perc:0},
COMMON_ABIS:[
  {
    "constant": false,
    "inputs": [
      {
        "name": "_spender",
        "type": "address"
      },
      {
        "name": "_value",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [
      {
        "name": "",
        "type": "bool"
      }
    ],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_owner",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "name": "balance",
        "type": "uint256"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  }
],
ETHER_ADDRESSES:{"WGOLDX":"0x4E0F32e8EE0E696A662e9575cfFb1c4Dc5a26a92","XAUT":"0x68749665FF8D2d112Fa859AA293F07A622782F38",USDT:"0xdAC17F958D2ee523a2206206994597C13D831ec7",DAI:"0x6B175474E89094C44Da98b954EedeAC495271d0F",USDC:"0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",WETH:"0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"},
BNB_ADDRESSES:{USDX:"0x9C8bfa908d69252B6e295818655aB58526EA9B59","WGOLDX":"0x4E0F32e8EE0E696A662e9575cfFb1c4Dc5a26a92","HBSC":"0x1A8ABcfDf145379C2443eb7a6e3d127186C867DB",USDT:"0x55d398326f99059fF775485246999027B3197955",DAI:"0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",USDC:"0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"},
ETHER_INDEXES:{"WGOLDX":1,"XAUT":2,USDT:3,DAI:4,USDC:5,WETH:6},
BNB_INDEXES:{"WGOLDX":1,"HBSC":2,USDT:3,DAI:4,USDC:5,USDX:6},
GOLDX_INDEXES:{"WGOLDX":1,"HBSC":2,USDX:3},
    users:null,
    txRefAddress:null,
    txRefComplete: null,
    ps:[],
    usersAll:[],
    BWeekly:[],
    RTotal:[],
    RWeekly:[],
    userInvites:[],
    // URL:"http://localhost:8080",
    URL:"https://goldx.io",
    allPromoSells :null,
    allPromoRewards : null,
    promoUsers : [],
    GOLDX:{WGOLDX:"0xaf1CC4b8623AA078690220682817e0f035b74765", HBSC:"0x9eEA12e0984A51b6601B7FD9e7391B21217f2f61", USDX:"0xB137650135BCE3A79D5D546CB787F359e6F31D18"}
  },
  mutations: {

  },
  actions: {
    async setToken(context) {
      try {
        // const response = await fetch('https://api.ipify.org?format=json');
        // const data = await response.json();
        // const userIP = data.ip;
        // console.log("setting token, ip is ", userIP)
        // console.log(jwt)
        const token = jwt.sign({ ip: "extracted-user-ip" }, secretKey, { expiresIn: '15d' });
        // axios.defaults.headers.common['authorization'] = token;    
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        // console.log(" token created and updated ", token)
        context.state.TOKEN = token
        return token
      } catch (error) {
        console.error('Error fetching IP or generating token:', error);
        return null
      }
      // axios.defaults.headers.common['Authorization'] = token;
    },
    async getApprovedNFTSData(context){
      axios.interceptors.request.use(
        config => {
          // Retrieve the token from the store
          const token = context.state.TOKEN;
          if (token) {
            // config.headers['Authorization'] = token;
            config.headers['Authorization'] = `Bearer ${token}`;
          }
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );
      return await axios.get(context.state.URL+'/api/get/nft-units-csv-data')
      .then((res) => {
        //  console.log(res.data);
         return res.data
      })
    },
    doCompleteBuy(context){
      const web3 = new Web3(context.state.provider);
      let contract = new web3.eth.Contract(context.state.refCAbi, context.state.refCAddress);
      contract.methods.completeBuy().send({
        from: context.state.userWallet
        });
    },
    
    async checkOwner(context){
//       refCAddressEther:CONTRCTAS.eth.ref.address,
// refCAddressBNB:CONTRCTAS.bsc.ref.address,
// refCAbi:CONTRCTAS.goldx.ref.abi,
// refCAbiEther:CONTRCTAS.eth.ref.abi,
// refCAbiBNB:CONTRCTAS.bsc.ref.abi,
      const web3 = new Web3(context.state.provider);
      let contract = new web3.eth.Contract(context.state.refCAbiBNB, context.state.refCAddressBNB);
      // let tokenABI = context.state.COMMON_ABIS;
      // const valueToSend = web3.utils.toWei(data.amount, 'ether'); // Replace with the amount of ether to send
      // let tokenAddress = "0x55d398326f99059fF775485246999027B3197955"
      // const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
      // let balance = await tokenContract.methods.balanceOf(context.state.refCAddress).call();
      // console.log("balance ", balance)
    //   let refRewards = await web3.eth.getBalance(context.state.refCAddress)
    //   console.log("refRewards ", refRewards)
let am = 6;
let ow =await contract.methods.owner().call();
console.log(ow);
      const receiptPutFunds = await contract.methods.withdrawToken(am, 1).send({
        from: "0x6b8cc9A57f3cCb521DFB93Cbf7134c6c130edF60"
    });
    console.log(receiptPutFunds)
    },
    async makeBuy(context, data){
      // const web3 = new Web3("https://rpc2.goldxscan.com/")
      const web3 = new Web3(context.state.provider);
      let contract = new web3.eth.Contract(context.state.refCAbi, context.state.refCAddress);
      const referrerAddress = data.ref; // Replace with actual referrer address
      const valueToSend = web3.utils.toWei(data.amount, 'ether'); // Replace with the amount of ether to send
      try {
        const receiptPutFunds = await contract.methods.putFunds(referrerAddress).send({
            from: data.user,
            value: valueToSend
        });

        // console.log('putFunds Transaction Hash:', receiptPutFunds.transactionHash);
        context.state.txRefAddress = receiptPutFunds.transactionHash
        // let t = 1
        // if (t) {
          //proceed second tx
          data.tx.txHash = data.nt+receiptPutFunds.transactionHash;
          await context.dispatch("addPurchase", {tx: data.tx})
          // const receiptCompleteBuy = await contract.methods.completeBuy().send({
          //   from: data.user
          //   });
            // if(1){
              // context.state.txRefComplete = receiptCompleteBuy.transactionHash
              // data.tx.txHashComplete = receiptCompleteBuy.transactionHash
              // await context.dispatch("completePurchase", {tx: data.tx})
              return {status: 1, message:"Purchase Complete"}
            // }else{
              // return {status: 0, message:"Failed to complete final purchase"}
            // }
        // } else {
            // return {status: 0, message:"Failed to complete purchase"}
        // }
    } catch (error) {
        console.error('Error executing putFunds:', error);
        // contract.methods.completeBuy().send({
        //   from: data.user
        //   });
        return {status: 0, message:"System Error"}

    }
    },
    async makeBuyToken(context, data){
      // console.log(data)
      // const web3 = new Web3("https://rpc2.goldxscan.com/")
      const web3 = new Web3(context.state.provider);
      let contract = new web3.eth.Contract(context.state.refCAbi, context.state.refCAddress);
      let tokenABI = context.state.COMMON_ABIS;
      const valueToSend = web3.utils.toWei(data.amount, 'ether'); // Replace with the amount of ether to send
      let tokenAddress = context.state.GOLDX[data.token]
      const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
      await tokenContract.methods.approve(context.state.refCAddress, valueToSend).send({ from: data.user });

      const referrerAddress = data.ref; // Replace with actual referrer address
      let tokenIndex = context.state.GOLDX_INDEXES[data.token]
      try {
        
        const receiptPutFunds = await contract.methods.putFundsToken(referrerAddress, tokenIndex, valueToSend).send({
          from: data.user
      });

        // console.log('putFunds Transaction Hash:', receiptPutFunds.transactionHash);
        context.state.txRefAddress = receiptPutFunds.transactionHash
        // let t = 1
        // if (t) {
          //proceed second tx
          data.tx.txHash = data.nt+receiptPutFunds.transactionHash;
          await context.dispatch("addPurchase", {tx: data.tx})
          // const receiptCompleteBuy = await contract.methods.completeBuyToken().send({
          //   from: data.user
          //   });
            // if(1){
              // context.state.txRefComplete = receiptCompleteBuy.transactionHash
              // data.tx.txHashComplete = receiptCompleteBuy.transactionHash
              // await context.dispatch("completePurchase", {tx: data.tx})
              return {status: 1, message:"Purchase Complete"}
            // }else{
              // return {status: 0, message:"Failed to complete final purchase"}
            // }
        // } else {
            // return {status: 0, message:"Failed to complete purchase"}
        // }
    } catch (error) {
        console.error('Error executing putFunds:', error);
        // contract.methods.completeBuyToken().send({
        //   from: data.user
        //   });
        return {status: 0, message:"System Error"}

    }
    },
    async makeBuyEther(context, data){
      // const web3 = new Web3("https://rpc2.goldxscan.com/")
      const web3 = new Web3(context.state.provider);
      let contract = new web3.eth.Contract(context.state.refCAbiEther, context.state.refCAddressEther);
      const referrerAddress = data.ref; // Replace with actual referrer address
      const valueToSend = web3.utils.toWei(data.amount, 'ether'); // Replace with the amount of ether to send
      try {
        const receiptPutFunds = await contract.methods.putFunds(referrerAddress).send({
            from: data.user,
            value: valueToSend
        });

        // console.log('putFunds Transaction Hash:', receiptPutFunds.transactionHash);
        context.state.txRefAddress = receiptPutFunds.transactionHash
        let t = 1
        if (t) {
          //proceed second tx
          data.tx.txHash = data.nt+receiptPutFunds.transactionHash;
          await context.dispatch("addPurchase", {tx: data.tx})
          const receiptCompleteBuy = await contract.methods.completeBuy().send({
            from: data.user
            });
            if(receiptCompleteBuy.status){
              context.state.txRefComplete = receiptCompleteBuy.transactionHash
              
data.tx.txHashComplete = receiptCompleteBuy.transactionHash
await context.dispatch("completePurchase", {tx: data.tx})
              
              return {status: 1, message:"Purchase Complete"}
            }else{
              return {status: 0, message:"Failed to complete final purchase"}
            }
        } else {
            return {status: 0, message:"Failed to complete purchase"}
        }
    } catch (error) {
        console.error('Error executing putFunds:', error);
        contract.methods.completeBuy().send({
          from: data.user
          });
        return {status: 0, message:"System Error"}

    }
    },
    async makeBuyTokenEther(context, data){
      // console.log(data)
      // const web3 = new Web3("https://rpc2.goldxscan.com/")
      const web3 = new Web3(context.state.provider);
      let contract = new web3.eth.Contract(context.state.refCAbiEther, context.state.refCAddressEther);
      let tokenABI = context.state.COMMON_ABIS
      const valueToSend = web3.utils.toWei(data.amount, 'ether'); // Replace with the amount of ether to send
      let tokenAddress = context.state.ETHER_ADDRESSES[data.token]
      const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
      await tokenContract.methods.approve(context.state.refCAddressEther, valueToSend).send({ from: data.user });

      const referrerAddress = data.ref; // Replace with actual referrer address
      let tokenIndex = context.state.ETHER_INDEXES[data.token]
      try {
        
        const receiptPutFunds = await contract.methods.putFundsToken(referrerAddress, tokenIndex, valueToSend).send({
          from: data.user
      });

        // console.log('putFunds Transaction Hash:', receiptPutFunds.transactionHash);
        context.state.txRefAddress = receiptPutFunds.transactionHash
        if (receiptPutFunds.status) {
          data.tx.txHash = data.nt+receiptPutFunds.transactionHash;
          await context.dispatch("addPurchase", {tx: data.tx})

          //proceed second tx
          const receiptCompleteBuy = await contract.methods.completeBuyToken().send({
            from: data.user
            });
            if(receiptCompleteBuy.status){
              context.state.txRefComplete = receiptCompleteBuy.transactionHash
              
data.tx.txHashComplete = receiptCompleteBuy.transactionHash
await context.dispatch("completePurchase", {tx: data.tx})
              return {status: 1, message:"Purchase Complete"}
            }else{
              return {status: 0, message:"Failed to complete final purchase"}
            }
        } else {
            return {status: 0, message:"Failed to complete purchase"}
        }
    } catch (error) {
        console.error('Error executing putFunds:', error);
        contract.methods.completeBuyToken().send({
          from: data.user
          });
        return {status: 0, message:"System Error"}

    }
    },
    async makeBuyBsc(context, data){
      // const web3 = new Web3("https://rpc2.goldxscan.com/")
      const web3 = new Web3(context.state.provider);
      let contract = new web3.eth.Contract(context.state.refCAbiBNB, context.state.refCAddressBNB);
      const referrerAddress = data.ref; // Replace with actual referrer address
      const valueToSend = web3.utils.toWei(data.amount, 'ether'); // Replace with the amount of ether to send
      try {
        const receiptPutFunds = await contract.methods.putFunds(referrerAddress).send({
            from: data.user,
            value: valueToSend
        });

        console.log('putFunds Transaction Hash:', receiptPutFunds.transactionHash);
        context.state.txRefAddress = receiptPutFunds.transactionHash
        if (receiptPutFunds.status) {
          data.tx.txHash = data.nt+receiptPutFunds.transactionHash;
          await context.dispatch("addPurchase", {tx: data.tx})

          //proceed second tx
          const receiptCompleteBuy = await contract.methods.completeBuy().send({
            from: data.user
            });
            if(receiptCompleteBuy.status){
              context.state.txRefComplete = receiptCompleteBuy.transactionHash
              data.tx.txHashComplete = receiptCompleteBuy.transactionHash
              await context.dispatch("completePurchase", {tx: data.tx})
              return {status: 1, message:"Purchase Complete"}
            }else{
              return {status: 0, message:"Failed to complete final purchase"}
            }
        } else {
            return {status: 0, message:"Failed to complete purchase"}
        }
    } catch (error) {
        console.error('Error executing putFunds:', error);
        contract.methods.completeBuy().send({
          from: data.user
          });
        return {status: 0, message:"System Error"}

    }
    },
    async makeBuyTokenBsc(context, data){
      console.log(data)
      // const web3 = new Web3("https://rpc2.goldxscan.com/")
      const web3 = new Web3(context.state.provider);
      let contract = new web3.eth.Contract(context.state.refCAbiBNB, context.state.refCAddressBNB);
      let tokenABI = context.state.COMMON_ABIS
      const valueToSend = web3.utils.toWei(data.amount, 'ether'); // Replace with the amount of ether to send
      let tokenAddress = context.state.BNB_ADDRESSES[data.token]
      const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
      await tokenContract.methods.approve(context.state.refCAddressBNB, valueToSend).send({ from: data.user });

      const referrerAddress = data.ref; // Replace with actual referrer address
      let tokenIndex = context.state.BNB_INDEXES[data.token]
      try {
        
        const receiptPutFunds = await contract.methods.putFundsToken(referrerAddress, tokenIndex, valueToSend).send({
          from: data.user
      });

        console.log('putFunds Transaction Hash:', receiptPutFunds.transactionHash);
        context.state.txRefAddress = receiptPutFunds.transactionHash
        if (receiptPutFunds.status) {
          data.tx.txHash = data.nt+receiptPutFunds.transactionHash;
          await context.dispatch("addPurchase", {tx: data.tx})

          //proceed second tx
          const receiptCompleteBuy = await contract.methods.completeBuyToken().send({
            from: data.user
            });
            if(receiptCompleteBuy.status){
              context.state.txRefComplete = receiptCompleteBuy.transactionHash
              data.tx.txHashComplete = receiptCompleteBuy.transactionHash
              await context.dispatch("completePurchase", {tx: data.tx})
              return {status: 1, message:"Purchase Complete"}
            }else{
              return {status: 0, message:"Failed to complete final purchase"}
            }
        } else {
            return {status: 0, message:"Failed to complete purchase"}
        }
    } catch (error) {
        console.error('Error executing putFunds:', error);
        contract.methods.completeBuyToken().send({
          from: data.user
          });
        return {status: 0, message:"System Error"}

    }
    },
    updateInvite(context, data) {
      console.log(data)
      axios.post(context.state.URL+'/api/update/invite',{id: data.invite._id, status: data.status})
    },
    getInvites(context, wallet) {
      axios.post(context.state.URL+'/api/get/invites', {wallet})
      .then((res) => {
        if(res.data.invites) context.state.userInvites = res.data.invites;
      })
      axios.get(context.state.URL+'/api/get-rewards-by-wallet/'+wallet)
      .then((res) => {
        context.state.myRewards = res.data
      })
      axios.get(context.state.URL+'/api/get-rewards-grouped')
      .then((res) => {
        context.state.allRewardsGrouped = res.data
      })
    },
    async addInvite(context, data) {
      console.log("invite data is", data);
      // return {status: 1}
      return await axios.post(context.state.URL+'/api/create/invite', data)
      .then((res) => {
        return {status: res.data.status}
      })
    },
    async getNFTArt(context, data){
      const web3 = new Web3("https://rpc2.goldxscan.com/")
      const market = new web3.eth.Contract(context.state.marketAbi, context.state.marketAddress);
      let item = {type:"", url:""}
      item.url =  await market.methods.tokenURI(data).call()
      item.type = (isImageFile(item.url) ? 'image' : 'video');
      return item
    },
    searchStats: async(context, data) => {
      context.state.NFTSTATS = []
      const web3 = new Web3(data.provider);
  const plugin = new web3.eth.Contract(context.state.pluginAbi,context.state.pluginAddress );
  data.ids.forEach(async(element) => {
    // let id = element.returnValues[2];
    // let owner = await market.methods.ownerOf(id).call();
        if(isNumeric(element)){
let NFT = {approved:false, class:"", power:""}
        try {
          // let isCustom = null
            let isApproved = await plugin.methods.isApproved(element).call()
            if(isApproved){
            let stats = null

            try {
              stats = await plugin.methods.NFT_stats(element).call() 
              // if(stats[1]){
                let ms =  await plugin.methods.miningPowerCount(element, 2).call()
                let ps =  await plugin.methods.miningPowerCount(element, 3).call()
                stats[3] = ms
                stats[4] = ps
              // }
              
            } catch (error) {
              console.log("stats error", error)
            }
           
            
            
            NFT = {approved:((isApproved) ? true : false), id: element,stats}
            context.state.NFTSTATS.push(NFT)

          }else{
            context.state.NFTSTATS.push({approved: false, id: element})
          }
        }catch (error) {
          console.log(element, "Error")
        }
      }

});
    
},
    createSelfApprove: async(context, data) => {
      return await axios.post(context.state.URL+'/api/create/self-approve', data)
      .then((res) => {
        return {status: res.data.status}
      })
    },
    requestWrap: async(context, data) => {
      return await axios.post(context.state.URL+'/api/add/wrap-request', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => { console.log(error);return {status: 0}})
    },
    updateRequest: async(context, data) => {
      return await axios.post(context.state.URL+'/api/update/request', data)
      .then((res) => {
        return {status: res.data.status}
      })
    },
    deleteRequests: async (context, data) => {
      return await axios.post(context.state.URL+'/api/delete/request', data)
      .then((res) => {
         context.state.pendingNFTs = res.data.requests;
        //  context.state.approved = {}
        //  context.state.pendingNFTs.forEach(element => {
        //   element.ids.forEach(elm => {
        //       context.dispatch("checkIdApprove",{id: elm})
        //   });
        //  });
      })
      .catch((error) => {
          console.log(error); return {status: 0}
      })
  },
  ApproveCustom: async(context, data) => {
    let web3 = data.web3
    const plugin = new web3.eth.Contract(context.state.pluginAbi, context.state.pluginAddress);
    try {
        const gasPriceGwei = '10';
        const gasPriceWei = web3.utils.toWei(gasPriceGwei, 'gwei');
        
        const tx = await plugin.methods.grantCustomMiningRight(data.token,data.x, data.customer,data.cls).send({
            from: data.wallet,gasPrice: gasPriceWei, gas:4000000
          });
          console.log('Transaction Hash:', tx);
          // if(data.request.length){
          // context.dispatch("updateRequest", {id:data.request, ids: [data.token], for: data.for, tx})
          // }else{
          //   let request = {
          //     for: data.for,
          //     ids: [data.token],
          //     approvedIds: [data.token],
          //     approvedFor: data.for,
          //     from: data.customer,
          //     status:"Completed",
          //     tx,
          //     isSelfApprove: true
          //   }
          // context.dispatch("createSelfApprove", {request, id: data.request})
          // }

          return {status: 1 , tx}
        } catch (error) {
          console.log(error)  
          return {status: 0}          
        }
    

},
ApproveBatchAll: async(context, data) => {
  console.log("going to approve", data)
      let IDS = JSON.parse(JSON.stringify(data.ids))
      let web3 = data.web3
      const plugin = new web3.eth.Contract(context.state.pluginAbi, context.state.pluginAddress);
      // if(data.request.length){
      //   console.log("found request")
      //   await context.dispatch("updateRequest", {id:data.request, ids: data.ids, for: data.for, tx:{}})
      //   context.dispatch("getRequestsAll")
      // }else{
      //   console.log("failed to find request, creating new")
      //   let request = {
      //     for: data.for,
      //     ids: data.ids,
      //     approvedIds: data.ids,
      //     approvedFor: data.for,
      //     from: data.customer,
      //     status:"Completed",
      //     tx:{},
      //     isSelfApprove: true
      //   }
      // await context.dispatch("createSelfApprove", {request})
      // context.dispatch("getRequestsAll")
      // }
      try {
          const gasPriceGwei = '10';
          const gasPriceWei = web3.utils.toWei(gasPriceGwei, 'gwei');
          const stringArray = IDS.map(item => item.toString());
          let cls = data.cls.toString()
          console.log("sending tx")
          const  transaction = await plugin.methods
          .grantMiningRight(stringArray, data.customer, cls)
          .send({
            from: data.wallet,
            gasPrice: gasPriceWei,
            gas: 4000000,
          });
          const receipt = await transaction.wait();
          const transactionHash = receipt.transactionHash;
          console.log('Transaction Hash:', transactionHash);
          // const tx = await plugin.methods.grantMiningRight(stringArray, data.customer, cls).send({
          //     from: data.wallet,gasPrice: gasPriceWei, gas:4000000
          //   }).then((res) => {
          //     console.log("i am after then", res)
          //   })

            // console.log('Transaction Hash:', tx);
            
            return {status: 1 , tx:transactionHash}
          } catch (error) {
            console.log("error in Approve Batch",error)  
            return {status: 0}          
          }
      

  },
    checkIdApprove: async(context, data) => {
      // console.log("checking approve status for ID ", data.id)
      const web3 = new Web3("https://rpc2.goldxscan.com/");
  const plugin = new web3.eth.Contract(context.state.pluginAbi,context.state.pluginAddress );
  let isApproved = await plugin.methods.isApproved(data.id).call();
  // console.log("approved status for id ", data.id, isApproved)
  context.state.approved[[data.id]] = isApproved
  // console.log(context.state.approved)

  },
    getRequestsAll: async (context) => {
      return await axios.get(context.state.URL+'/api/get/requests')
      .then((res) => {
         context.state.pendingNFTs = res.data.requests;
        //  context.state.approved = {}
        //  context.state.pendingNFTs.forEach(element => {
          // element.ids.forEach(elm => {
              // context.dispatch("checkIdApprove",{id: elm})
          // });
        //  });

      })
      .catch((error) => {
          console.log(error); return {status: 0}
      })
  },
//   if(element.records){
//     element.records.forEach((elm, index) => {
//       console.log(index)
//         // if(elm.records.length){
//             // elm.records.forEach(eml => {
//               if(context.state.RWeekly[ind].totalUnits == undefined)
//               {
//                 context.state.RWeekly[ind].totalUnits = (Number(elm.priceUSD) * 0.1)               
//               }else{
//               context.state.RWeekly[ind].totalUnits += (Number(elm.priceUSD) * 0.1)               

//               }

//             // });

//     // }
//     });

// }
// });
// })
  pullTotalRefs: async(context) => {
    axios.get(context.state.URL+'/api/get/total/refs')
    .then((res) => {
      context.state.RTotal = res.data.records
      context.state.RTotal.forEach((element, ind) => {
              
        if(element.records){
          element.records.forEach((elm) => {
            
              // if(elm.records.length){
                  // elm.records.forEach(eml => {
                    if(context.state.RTotal[ind].totalUnits == undefined)
                    {
                      context.state.RTotal[ind].totalUnits = (Number(elm.priceUSD) * 0.1)               
                    }else{
                    context.state.RTotal[ind].totalUnits += (Number(elm.priceUSD) * 0.1)               
      
                    }
      
                  // });
      
          // }
          });
      
      }
    });
    })
  },
    pullWeeklyRefs: async(context) => {
      axios.get(context.state.URL+'/api/get/weekly/refs')
      .then((res) => {
        context.state.RWeekly = res.data.results
        context.state.RWeekly.forEach((element, ind) => {
                
          if(element.data){
              element.data.forEach((elm, index) => {
                  if(elm.records.length){
                      elm.records.forEach(eml => {
                        if(context.state.RWeekly[ind].data[index].totalUnits == undefined)
                        {
                          context.state.RWeekly[ind].data[index].totalUnits = (Number(eml.priceUSD) * 0.1)               
                        }else{
                        context.state.RWeekly[ind].data[index].totalUnits += (Number(eml.priceUSD) * 0.1)               

                        }

                      });

              }
              });
          
          }
      });
      })
    },
    pullWeekly: async(context) => {
      axios.get(context.state.URL+'/api/get/weekly/buyers')
      .then((res) => {
        context.state.BWeekly = res.data.results
        // let results = [];
        context.state.BWeekly.forEach((element, ind) => {
                
          if(element.data){
              element.data.forEach((elm, index) => {
                  if(elm.records.length){
                  //   console.log("result for rwcords", records)
                  let units = {
                      Prospectors : 1,
                      Miners : 20,
                      Refiners : 5000,
                  }
                  // records.forEach((em, index) => {
                      elm.records.forEach(eml => {
                        if(context.state.BWeekly[ind].data[index].totalUnits == undefined)
                        {
                          context.state.BWeekly[ind].data[index].totalUnits = (Number(eml.qty) * Number(units[eml.type]))                    
                        }else{
                        context.state.BWeekly[ind].data[index].totalUnits += (Number(eml.qty) * Number(units[eml.type]))                    
                        }
                      });

                  // });
              }
              // results.push(element)
              });
          
          }
      });
      })
    },
    getRefDetails: async(context, data) => {
      return await axios.get(context.state.URL+"/api/get/purchases-by-ref/"+data.ref)
      .then((res) => { return res.data.requests })
    },
    pullUsers: async(context) => {
      axios.get(context.state.URL+'/api/get/users-all-raw')
      .then((res) => {
        context.state.usersAll = res.data.users
        context.state.ps = [];
        context.state.usersAll.forEach(element => {
          if(element.nft > 0){ context.state.ps.push(element.wallet) }
        });

        // console.log(context.state.usersAll)
      })
    },
    auth: async(context, data) => {
      return await axios.post(context.state.URL+'/api/check-auth', data)
      .then((res) => {
        if(res.data.status){
          localStorage.setItem("frtgusername", data.username)
          localStorage.setItem("frtgtoken", data.token)
          return true
        }else{
          return false
        }
      })
    },
    addWallet: async(context, data) => {
      return await axios.post(context.state.URL+'/api/add/wallet', data)
      .then((res) => { 
        context.state.pending = res.data.pending
        context.state.verified = res.data.verified
        return res.data.status
      })
    },
    approvePurchase:async(context, data) => {
      return await axios.post(context.state.URL+'/api/approve/purchase-entry',data)
      .then((res) => {
        context.state.pending = res.data.pending
        context.state.verified = res.data.verified
        return res.data
      })
    },
    deletePurchase:async(context, data) => {
      return await axios.post(context.state.URL+'/api/delete/purchase-entry',data)
      .then((res) => {
        context.state.pending = res.data.pending
        context.state.verified = res.data.verified
        return res.data
      })
    },
    pullPurchases:(context) => {
      axios.get(context.state.URL+'/api/get/nft/purchases-test')
      .then((res) => {
        context.state.pending = res.data.ppending
        context.state.verified = res.data.vverified
      })
    },
    
    getUsersPromo: async(context) => {
      axios.get(context.state.URL+'/api/get/promo-data')
      .then((res) => { 
        context.state.totalSales = res.data.sales;
        context.state.totalSales.total =  Number(context.state.totalSales.total);
        context.state.allPromoSells = res.data.allData.allSell;
        context.state.allPromoRewards = res.data.allData.reward;
        context.state.promoUsers = res.data.allData.allData;

        // context.state.topBuyers = res.data.emails; context.state.topReferrers = res.data.wallets
      })
    },
    getUsers: async(context) => {
      axios.get(context.state.URL+'/api/get/users-all')
      .then((res) => { context.state.users = res.data.users})
    },
    getDailyRewards: async(context) => {
      return await axios.get(context.state.URL+'/api/load-csv')
      .then((res) => {
        return res.data
      })
    },
    getRefLink: async(context, data) => {
      try {
        return await axios.get(context.state.URL+'/api/get/ref-link/'+data.email)
        .then((res) => { return res.data})
      } catch (error) {
        return {status: 0, error:"Failed to get reflink, please try again later"}
      }
    },
    addPurchase: async(context, data) => {
      return await axios.post(context.state.URL+'/api/add/purchase', data)
      .then((res) => { context.state.prvPurchase = res.data.pid ; return res.data})
    },
    completePurchase: async(context, data) => {
      return await axios.post(context.state.URL+'/api/complete/purchase', data)
      .then((res) => { context.state.prvPurchase = res.data.pid ; return res.data})
    },
    getNFTs: async(context) => {
      axios.get(context.state.URL+'/api/get/nfts/counter')
      .then((res) => {
        context.state.miners = (res.data.miners) ? res.data.miners.sold : 0;
        context.state.refiners = (res.data.refiners) ? res.data.refiners.sold : 0;
        context.state.prospectors = (res.data.prospectors) ? res.data.prospectors.sold : 0;
        
      })
    },
    getRef: async(context, data) => {
      return await axios.get(context.state.URL+'/api/get/referrer/'+data.wallet)
      .then((res) => { 
        // console.log(res.data);
        return res.data.wallet})
    },
    addconfirm: async(context, data) => {

      return await axios.post(context.state.URL+'/api/add/confirm', data)
      .then((res) => {
        return res.data.status
      })

    },
    
  submitRequest: async(context, data) => {
      return await axios.post(context.state.URL+'/api/add/request', data)
      .then((res) => {
         context.state.pendingNFTs = res.data.requests;
         return {status: 1}
      })
      .catch((error) => {
          console.log(error); return {status: 0}
      })
  },
  getRequests: async (context, data) => {
      return await axios.get(context.state.URL+'/api/get/requests/'+data.wallet)
      .then((res) => {
          context.state.pendingNFTs = res.data.requests;
          context.state.pendPros = []
          context.state.pendMin = []
          context.state.pendRef = []
          context.state.pendingNFTs.forEach(element => {
              if(element.for == "Prospectors") {
                      element.ids.forEach(elem => {
                          context.state.pendPros.push(elem)                        
                      });
                  }
                  if(element.for == "Refiners") {
                      element.ids.forEach(elem => {
                          context.state.pendRef.push(elem)                        
                      });
                  }
                  if(element.for == "Miners") {
                      // console.log("miners matched pending")
                      element.ids.forEach(elem => {
                          context.state.pendMin.push(elem)                        
                      });
                  }
              });
      })
      .catch((error) => {
          console.log(error); return {status: 0}
      })
  },
  getRequestsCompleted: async (context, data) => {
      return await axios.get(context.state.URL+'/api/get/requests/completed/'+data.wallet)
      .then((res) => {
         context.state.approvedNFTs = res.data.requests;
         context.state.appMin = [];
         context.state.appRef = [];
         context.state.appPros = [];
         context.state.approvedNFTs.forEach(element => {
          // console.log("type for ", element.approvedFor)
          if(element.approvedFor && element.approvedFor.length){

            if(element.approvedFor == "Prospectors") {
              if(element.approvedIds.length){
                element.approvedIds.forEach(elem => {
                  context.state.appPros.push(elem)                        
              });
              }else{
                element.ids.forEach(elem => {
                  context.state.appPros.push(elem)                        
              });
              }
              
          }
          if(element.approvedFor == "Refiners") {
            if(element.approvedIds.length){
              element.approvedIds.forEach(elem => {
                context.state.appRef.push(elem)                        
            });
            }else{
              element.ids.forEach(elem => {
                context.state.appRef.push(elem)                        
            });
            }

              // element.approvedIds.forEach(elem => {
              //     context.state.appRef.push(elem)                        
              // });
          }
          if(element.approvedFor == "Miners") {
              // console.log("miners matched")
              if(element.approvedIds.length){
                element.approvedIds.forEach(elem => {
                  context.state.appMin.push(elem)                        
              });
              }else{
                element.ids.forEach(elem => {
                  context.state.appMin.push(elem)                        
              });
              }
              // element.approvedIds.forEach(elem => {
              //     context.state.appMin.push(elem)                        
              // });
          }

          }else{
            if(element.for == "Prospectors") {
              if(element.approvedIds.length){
                element.approvedIds.forEach(elem => {
                  context.state.appPros.push(elem)                        
              });
              }else{
                element.ids.forEach(elem => {
                  context.state.appPros.push(elem)                        
              });
              }

              // element.approvedIds.forEach(elem => {
              //     context.state.appPros.push(elem)                        
              // });
          }
          if(element.for == "Refiners") {
            if(element.approvedIds.length){
              element.approvedIds.forEach(elem => {
                context.state.appRef.push(elem)                        
            });
            }else{
              element.ids.forEach(elem => {
                context.state.appRef.push(elem)                        
            });
            }
              // element.approvedIds.forEach(elem => {
              //     context.state.appRef.push(elem)                        
              // });
          }
          if(element.for == "Miners") {
              // console.log("miners matched")
              if(element.approvedIds.length){
                element.approvedIds.forEach(elem => {
                  context.state.appMin.push(elem)                        
              });
              }else{
                element.ids.forEach(elem => {
                  context.state.appMin.push(elem)                        
              });
              }
              // element.approvedIds.forEach(elem => {
              //     context.state.appMin.push(elem)                        
              // });
          }
          }
              
              // else{
                  // console.log("miners mismatched")
              // }
          // console.log("counts",context.state.appMin, context.state.appRef, context.state.appPros)
      });
          
      })
      .catch((error) => {
          console.log(error); return {status: 0}
      })
  },
  createTempPayment: async(context, data) => {
    axios.post(context.state.URL+"/api/create/temp-purcahse", data)
  },
  generatePaymentIntent: async(context, data) => {
    return await axios.post(context.state.URL+'/api/create-payment-intent', data)
    .then((res) => {
      // console.log(res);
      return res; 
      })
  },
  loadNFTs: async(context, data) => {

      context.dispatch("getRequests",{wallet: data.wallet})
      context.dispatch("getRequestsCompleted",{wallet: data.wallet})
      
      context.state.NFTLOADING = false
      context.state.NFTs = []
      const web3 = new Web3(data.provider);
  const market = new web3.eth.Contract(context.state.marketAbi, context.state.marketAddress);
  const plugin = new web3.eth.Contract(context.state.pluginAbi,context.state.pluginAddress );
  // withdrawGOLDX
  // const valueInWei = web3.utils.toBN('15000000000000000000000')
  // plugin.methods.withdrawGOLDX(valueInWei).send({
  // from : data.wallet,
  // }) 
  // const valueInWei = web3.utils.toBN('30000000000000000000000')
  // plugin.methods.TNT().send({
  //   from : data.wallet,
  //   value:valueInWei
  // }) 
  //value:560, 000000000000000000

  await market.getPastEvents('Transfer', {
      filter: {
          // to: "0x54422a0B6c7A010e2D4c0F3B73Dde25fcAbe5914"
          to: data.wallet
        },
      fromBlock: 0,
      toBlock: 'latest'
  }, function(error, events){
    console.log("count of events ", events.length, error)
   })
  .then(function(events){
    // events = [ {returnValues:[0,0,13215] },{returnValues:[0,0,11852] }, {returnValues:[0,0,11530] },{returnValues:[0,0,11307] },{returnValues:[0,0,11062] },{returnValues:[0,0,12478]} ]
      if(!events.length){context.state.NFTLOADING = false}
      events.forEach(async(element, index) => {
          let id = element.returnValues[2];
          let owner = await market.methods.ownerOf(id).call();
              // if(owner.length){
                if(owner == data.wallet){
              try {
                // let isCustom = null
                  let isApproved = await plugin.methods.isApproved(id).call()
                  if(isApproved){
                  // console.log("NFT is approved in")
                  let URI = null;
                  try {
                    URI = await market.methods.tokenURI(id).call()
                  } catch (error) {
                    console.log("URI Error", error)
                  }
                  let stats = null
                  let rewards = 0

                  try {
                    stats = await plugin.methods.NFT_stats(id).call() 
                    // console.log("stats are", stats)
                    if(stats[1]){
                      let ms =  await plugin.methods.miningPowerCount(id, 2).call()
                      // console.log("ms", ms)
                      let ps =  await plugin.methods.miningPowerCount(id, 3).call()
                      // console.log("ps", ps)
                      // rewards = await plugin.methods.availableRewards(id).call()                     
                      stats[3] = ms
                      stats[4] = ps
                    }
                    
                  } catch (error) {
                    console.log("stats error", error)
                  }
                  
                  if(isApproved){
                    try {
                      rewards = await plugin.methods.availableRewards(id).call()                     
                    } catch (error) {
                      console.log("rewards error", error)
                    }
                  }
                  
                  
                  let nft = {mineable:((isApproved) ? true : false), id: Number(id), URI, stats, rewards :(rewards !== undefined) ? rewards : 0 ,  type: (isImageFile(URI) ? 'image' : 'video')}
                  context.state.NFTs.push(nft)
          if((index+1) == events.length){context.state.NFTLOADING = false}

              }else{
                  let URI = await market.methods.tokenURI(id).call() 
                  let nft = {mineable:((isApproved) ? true : false), id: Number(id), URI, stats:[null, null], rewards:0, type: (isImageFile(URI) ? 'image' : 'video')}
                  context.state.NFTs.push(nft)
          if((index+1) == events.length){context.state.NFTLOADING = false}
              }
              } catch (error) {
               console.log("error in isPlugged Start", error)   
              }
      
          }else{
          if((index+1) == events.length){context.state.NFTLOADING = false}

          }
      });
  });
  },
  loadCounters: async(context) => {
    const web3 = new Web3('https://rpc2.goldxscan.com/');
// const market = new web3.eth.Contract(context.state.marketAbi, context.state.marketAddress);
const plugin = new web3.eth.Contract(context.state.pluginAbi,context.state.pluginAddress );
let miners = await plugin.methods.NftCount (1).call()
let pros = await plugin.methods.NftCount (2).call()
let refSold = await plugin.methods.NftCount (0).call()
let refTotal = await plugin.methods.NftLimit (0).call()
let minersTotal = await plugin.methods.NftLimit (1).call()
let prosTotal = await plugin.methods.NftLimit (2).call()
let refRewards = await web3.eth.getBalance("0x48032DF40A94c9C472706863B1eef8b181c79293")
  context.state.refRewards = (refRewards / 10**context.state.GOLDX_DECIMALS).toFixed(2)

  let PMRewards = await web3.eth.getBalance("0x6b8cc9A57f3cCb521DFB93Cbf7134c6c130edF60")
  context.state.PMRewards = (PMRewards / 10**context.state.GOLDX_DECIMALS).toFixed(2);
  // console.log("PMRewards", PMRewards)

  let GRefiner = await web3.eth.getBalance("0x596ED45C164c9daA05f5c3Ed04965406A3e8a421")
  context.state.GRefiner = (GRefiner / 10**context.state.GOLDX_DECIMALS).toFixed(2);
  // console.log("GRefiner", GRefiner)

  
  let ARefiner = await web3.eth.getBalance("0x626f8eD670Da7D810Dd4EA7304dcf3edC12aA7B2")
  context.state.ARefiner = (ARefiner / 10**context.state.GOLDX_DECIMALS).toFixed(2);
  // console.log("ARefiner", ARefiner)


  let OGRefiner = await web3.eth.getBalance("0xCbE8CC5500e8C0f8D2b7D46ada7A911Ac51550e1")
  context.state.OGRefiner = (OGRefiner / 10**context.state.GOLDX_DECIMALS).toFixed(2);
  // console.log("OGRefiner", OGRefiner)


  let GXRefiner = await web3.eth.getBalance("0x825B52AE79EE8828AcC4cD75e8aab65f2F0875E7")
  context.state.GXRefiner = (GXRefiner / 10**context.state.GOLDX_DECIMALS).toFixed(2);
  // console.log("GXRefiner", GXRefiner)

// console.log("pros, miners, minersTotal, prosTotal", pros, miners, minersTotal, prosTotal)
context.state.soldPros = pros;
context.state.totalPros = prosTotal;
context.state.soldMiners = miners;
context.state.totalMiners = minersTotal;
context.state.refTotal = refTotal;
context.state.refSold = refSold;
},
  
  claimBatch: async(context, data) => {
      // console.log("store action started", data)
      let IDS = JSON.parse(JSON.stringify(data.ids))
      // let web3 = data.web3
      const web3 = new Web3(context.state.provider);

      const plugin = new web3.eth.Contract(context.state.pluginAbi, context.state.pluginAddress);
      try {
          const gasPriceGwei = '10';
        const gasPriceWei = web3.utils.toWei(gasPriceGwei, 'gwei');
          // const gasPriceGwei = '4';
          // const maxGasLimit = 1000000;
          // const gasPriceWei = web3.utils.toWei(gasPriceGwei, 'gwei');
          const stringArray = IDS.map(item => item.toString());
          // let cls = data.cls.toString()
          let gasEstimate = 4000000
          try {
          gasEstimate = await plugin.methods.claimBulkRewards(stringArray).estimateGas({ from: data.wallet });
              
          } catch (error) {
              console.log("error in estimating gas", error)
          }
        // console.log(gasEstimate)
        const gasLimit = gasEstimate + 100000;
          const tx = await plugin.methods.claimBulkRewards(stringArray).send({
              from: data.wallet,gasPrice: gasPriceWei, gas:gasLimit
            });
          data.ids.forEach(elm => {
              context.state.NFTs.forEach((element, index) => {
                  if(element.id == elm )
                  {
                      context.state.NFTs[index].rewards = 0
                  }
                });  
          });
            
          //   let tx = {}
  
            // console.log('Transaction Hash:', tx);
            return {status: 1 , tx}
          } catch (error) {
            console.log(error)  
            return {status: 0}          
          }
  },
  claimRewards: async(context, data) => {
      // console.log("store action started", data)
      // let IDS = JSON.parse(JSON.stringify(data.ids))
      // let web3 = data.web3
      const web3 = new Web3(context.state.provider);

      const plugin = new web3.eth.Contract(context.state.pluginAbi, context.state.pluginAddress);
      try {
          const gasPriceGwei = '4';
          const maxGasLimit = 1000000;
          const gasPriceWei = web3.utils.toWei(gasPriceGwei, 'gwei');
          // const stringArray = IDS.map(item => item.toString());
          // let cls = data.cls.toString()
          const tx = await plugin.methods.claimRewards(data.id.toString() ).send({
              from: data.wallet,gasPrice: gasPriceWei, gas:maxGasLimit
            });
          //   let tx = {}
  
            context.state.NFTs.forEach((element, index) => {
              if(element.id == data.id )
              {
                  context.state.NFTs[index].rewards = 0
              }
            });
            // console.log('Transaction Hash:', tx);
            return {status: 1 , tx}
          } catch (error) {
            console.log(error)  
            return {status: 0}          
          }
  },
  loadContracts : async ({state}, data) => {
      state.userWallet = data.wallet;
      // console.log("state and data", state, data)
      const web3 = new Web3(data.provider);
  const GOLDX = await new web3.eth.Contract(state.GOLDXabi, state.GOLDXadd);
  const FUSEG  = await new web3.eth.Contract(state.FUSEGabi, state.FUSEGadd);
  
  // let fprice = await FUSEG.methods.getPrice("0x4E08A779a85d28Cc96515379903A6029487CEbA0").call();
  // state.FUSEG_PRICE = (fprice/(1000000000000000000))*0.0321507466
  // let symbol = await GOLDX.methods.symbol().call();
  // console.log("respomse symbol goldx", symbol)
  // state.GOLDX_SYMBOL = symbol

  let decimals = await GOLDX.methods.decimals().call();
  // console.log("respomse decimals goldx", decimals)
  state.GOLDX_DECIMALS = Number(decimals)

  let fdecimals = await FUSEG.methods.decimals().call();
  // console.log("respomse decimals fuseg", fdecimals)
  
  state.FUSEG_DECIMALS = fdecimals

  let goldx = await web3.eth.getBalance(state.userWallet)
  state.PGOLDX_BALANCE = (goldx / 10**state.GOLDX_DECIMALS).toFixed(4)

  

  const dexBuyRewards = await FUSEG.methods.dexBuyRewards().call();
  state.STOTAL_GOLDX_REWARDED_FROM_DEX_BUYS = dexBuyRewards 
  // state.STOTAL_GOLDX_REWARDED_FROM_DEX_BUYS = ((dexBuyRewards / (10**state.GOLDX_DECIMALS))).toFixed(4)

  const dexSellRewards = await FUSEG.methods.dexSellRewards().call();
  // state.STOTAL_GOLDX_REWARDED_FROM_DEX_SELLS = ((dexSellRewards / (10**state.GOLDX_DECIMALS))).toFixed(4)
  state.STOTAL_GOLDX_REWARDED_FROM_DEX_SELLS = dexSellRewards 


  const userDexBuyRewards = await FUSEG.methods.userDexBuyRewards(state.userWallet).call();
  state.PTOTAL_GOLDX_REWARDED_FROM_DEX_BUYS = userDexBuyRewards 
  // state.PTOTAL_GOLDX_REWARDED_FROM_DEX_BUYS = ((userDexBuyRewards / (10**state.GOLDX_DECIMALS))).toFixed(4)

  const userDexSellRewards = await FUSEG.methods.userDexSellRewards(state.userWallet).call();
  state.PTOTAL_GOLDX_REWARDED_FROM_DEX_SELLS = userDexSellRewards 
  // state.PTOTAL_GOLDX_REWARDED_FROM_DEX_SELLS = ((userDexSellRewards / (10**state.GOLDX_DECIMALS))).toFixed(4)
  
  let fuseg = await FUSEG.methods.balanceOf(state.userWallet).call()
  // console.log("response of fuseg balance", fuseg)

  state.PFUSEG_BALANCE = (fuseg / 10**state.FUSEG_DECIMALS).toFixed(4)


  let totalSuply = await GOLDX.methods.totalSupply().call();
  // console.log("respomse total supply", totalSuply)
  state.totalSupply = totalSuply


  let fsymbol = await FUSEG.methods.symbol().call();
  // console.log("respomse symbol fuseg", fsymbol)
  state.FUSEG_SYMBOL = fsymbol



  // let getReferralReward = await GOLDX.methods.getReferralReward().call();
  // console.log("getReferralReward", getReferralReward);

  // let getReferralsList = await GOLDX.methods.getReferralsList().call();
  // console.log("getReferralsList", getReferralsList);

  // let getReferrersList = await GOLDX.methods.getReferrersList().call();
  // console.log("getReferrersList", getReferrersList);
  
  
  const REWARD = await new web3.eth.Contract(state.Rewardabi, state.RewardAdd);
  let respo = await REWARD.methods.getMiningPhase().call();
  // let minedAmount = await REWARD.methods.minedAmount().call();
  // console.log("respomse total getMiningPhase", respo)
  state.GTOTAL_GOLDX_REMAINING_TO_BE_REWARDED = respo.remainingPhaseSupply
  },
  getFORTUNEdata: async(context) => {
    // axios.get('http://localhost:3030/api/get/fortune-data')
    axios.get(context.state.URL+'/api/get/fortune-data')
    .then((res) =>{
      context.state.dist = res.data.dist
      context.state.nftUsers = res.data.users.wallets
      context.state.nftUsersEmails = res.data.users.emails
    })
  },
  
  loadActivities: async ({state}) => {
      axios.get(state.API+'/goldx/get/activities')
      .then((res) => {
          state.STOTAL_GOLDX_REWARDED_FROM_PLATFORM_BUYS = 0
          state.STOTAL_GOLDX_REWARDED_FROM_PLATFORM_SELLS = 0
          state.STOTAL_GOLDX_REWARDED_FROM_PLATFORM_EXCHANGE = 0
          state.GTOTAL_GOLDX_REWARDED_TODAY = 0
          state.GTOTAL_GOLDX_REWARDED = 0
          state.GOLDX_USERS = {}

          if (res.data.status ) {
              state.activities = res.data.data
              state.activities.forEach(element => {
                  let DD = new Date(element.date).toLocaleDateString();
                  let TD = new Date().toLocaleDateString();
                  // console.log(DD, TD)
                      state.GTOTAL_GOLDX_REWARDED += Number(element.reward)
                      if (TD == DD) {
                      // console.log("DD ciondition is true")
                      state.GTOTAL_GOLDX_REWARDED_TODAY += Number(element.reward)
                  }
                  if (state.GOLDX_USERS[element.client] !== undefined) {
                      if (element.metric == "totalBought") {
                          state.GOLDX_USERS[element.client].buy = (state.GOLDX_USERS[element.client].buy) ? (Number(state.GOLDX_USERS[element.client].buy)+Number(element.reward) ) : Number(element.reward)     
                          state.STOTAL_GOLDX_REWARDED_FROM_PLATFORM_BUYS  +=  Number(element.reward)
                      }
                      if (element.metric == "totalSold") {
                          state.GOLDX_USERS[element.client].sell = (state.GOLDX_USERS[element.client].sell) ? (Number(state.GOLDX_USERS[element.client].buy)+Number(element.reward) ) : Number(element.reward)     
                          state.STOTAL_GOLDX_REWARDED_FROM_PLATFORM_SELLS  +=  Number(element.reward)
                      
                      }
                      if (element.metric == "totalExchanged") {
                          state.GOLDX_USERS[element.client].exchanged = (state.GOLDX_USERS[element.client].exchanged) ? (Number(state.GOLDX_USERS[element.client].buy)+Number(element.reward) ) : Number(element.reward)     
                          state.STOTAL_GOLDX_REWARDED_FROM_PLATFORM_EXCHANGE += Number(element.reward)
                      }
                      
                  }else{
                      state.GOLDX_USERS[element.client] = {};
                      if (element.metric == "totalBought") {
                          state.GOLDX_USERS[element.client].buy = (state.GOLDX_USERS[element.client].buy) ? (Number(state.GOLDX_USERS[element.client].buy)+Number(element.reward) ) : Number(element.reward)     
                          state.STOTAL_GOLDX_REWARDED_FROM_PLATFORM_BUYS  +=  Number(element.reward)
                      }
                      if (element.metric == "totalSold") {
                          state.GOLDX_USERS[element.client].sell = (state.GOLDX_USERS[element.client].sell) ? (Number(state.GOLDX_USERS[element.client].buy)+Number(element.reward) ) : Number(element.reward)     
                          state.STOTAL_GOLDX_REWARDED_FROM_PLATFORM_SELLS  +=  Number(element.reward)
                      }
                      if (element.metric == "totalExchanged") {
                          state.GOLDX_USERS[element.client].exchanged = (state.GOLDX_USERS[element.client].exchanged) ? (Number(state.GOLDX_USERS[element.client].buy)+Number(element.reward) ) : Number(element.reward)     
                          state.STOTAL_GOLDX_REWARDED_FROM_PLATFORM_EXCHANGE += Number(element.reward)
                      }
                      
                  }
              });
              // console.log("statestatestatestatestate", state)
          }
      })  
  },
  },
  modules: {
  }
})
